<template lang="pug">
  .stp
    .title
      router-link(:to="{ name: 'shared_training_programs_show', \
                         params: { id: sharedTrainingProgram.id } }")
        | {{ sharedTrainingProgram.title || 'Не указано название' }}
      span.icon
        font-awesome-icon.ml-1(icon="trash" @click="destroy")

    .select
      label Выбор учеников для доступа к программе тренировок
      v-select(:options="shortList" label="full_name" multiple v-model="selected" @input="changed = true")
      button.btn.btn-outline-primary(v-if="changed" @click="saveUsersList") Сохранить выбор

    .hr
      hr
</template>

<style lang="sass">
.stp
  .title
    .icon
      cursor: pointer
      color: rgba(red, .5)

      &:focus, &:hover, &:active
        color: red
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      selected: [],
      changed: false
    }
  },
  props: {
    sharedTrainingProgram: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    this.selected = this.sharedTrainingProgram.selected_users
  },
  computed: {
    ...mapGetters({
      shortList: 'coachPupils/shortList'
    })
  },
  methods: {
    ...mapActions({
      saveTrainingProgramPupils: 'sharedTrainingPrograms/saveTrainingProgramPupils',
      deleteTrainingProgram: 'sharedTrainingPrograms/deleteDestroy'
    }),
    saveUsersList () {
      this.saveTrainingProgramPupils({
        id: this.sharedTrainingProgram.id,
        users: this.selected
      }).then(() => {
        this.$notify({
          title: 'Настройка доступа',
          type: 'success',
          text: 'Доступ к программе тренировок сохранен'
        })

        this.changed = false
      })
    },
    destroy () {
      if (confirm('Удалить?')) {
        this.deleteTrainingProgram(this.sharedTrainingProgram.id)
      }
    }
  }
}
</script>
