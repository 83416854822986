<template lang="pug">
  .shared-training-programs
    .shared-training-programs__title
      .row
        .col-sm
          input(type="search" @input="search")
          router-link(:to="{ name: 'new_shared_training_program' }" active-class="active")
            font-awesome-icon.ml-2(icon="plus")

      .row.mt-3(v-if="!sharedTrainingPrograms.length")
        .col-12
          p Пока здесь ничего нет. Нажми на +, чтобы добавить первую программу тренировок

      .row.mt-3(v-if="sharedTrainingPrograms.length")
        item.col-12.col-sm-6.col-md-6.col-lg-3(v-for="item in sharedTrainingPrograms"
                            :shared-training-program="item"
                            :key="item.id")

    infinite-loading(@infinite="doLoad"  :identifier="q")
      div(slot="no-more")
      div(slot="no-results")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import item from './item'
import debounce from 'debounce'

export default {
  data () {
    return {
      q: ''
    }
  },
  mounted () {
    this.getPupilsShortList()
  },
  components: { InfiniteLoading, item },
  computed: {
    ...mapGetters({
      sharedTrainingPrograms: 'sharedTrainingPrograms/all'
    })
  },
  methods: {
    ...mapActions({
      getIndex: 'sharedTrainingPrograms/getIndex',
      getPupilsShortList: 'coachPupils/getShortList',
      nullifyPage: 'sharedTrainingPrograms/nullifyPage',
      nullifyTrainingPrograms: 'sharedTrainingPrograms/nullifyTrainingPrograms'
    }),
    doLoad ($state) {
      this.getIndex({ q: this.q }).then((response) => {
        if (response && response.length) {
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    search: debounce(function (evt) {
      this.q = evt.target.value

      this.nullifyPage()
      this.nullifyTrainingPrograms()
    }, 500)
  }
}
</script>
